import React from 'react';
import { BasicInput, BasicInputProps } from '@baracoda/albatross.ui.inputs.input';
import { Select, SelectItem } from '@baracoda/albatross.ui.inputs.select';
import { Button } from '@baracoda/albatross.ui.inputs.button';

const HomePage: React.FC = () => {
  const handleInputChange: BasicInputProps['onChange'] = (e) => {
    console.log('input change: ', e.target.value);
  };

  const handleSearch = () => {};
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '20px',
        }}
      >
        <div
          style={{
            width: '170px',
          }}
        >
          <Select value="email" variant="small">
            <SelectItem value="email">Email</SelectItem>
            <SelectItem value="toothbrushSN">Toothbrush SN</SelectItem>
          </Select>
        </div>
        <div
          style={{
            width: '300px',
          }}
        >
          <BasicInput variant="search" fullWidth={true} onChange={handleInputChange} />
        </div>
        <Button onClick={handleSearch}>Search</Button>
      </div>
    </div>
  );
};

export default HomePage;
