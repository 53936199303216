import React from 'react';
import { Skeleton as MuiSkeleton } from '@mui/material';

const Skeleton = () => {
  return (
    <>
      <MuiSkeleton height={66} width={150} />
      <MuiSkeleton height={66} width={300} />
      <MuiSkeleton height={66} width={450} />
      <MuiSkeleton height={66} width={600} />
    </>
  );
};

export default Skeleton;
