import React, { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';

interface GenericSummaryProps {
  title: string;
  fieldsCollection: [label: string, element: ReactElement | string | number][];
}

const GenericSummary = (props: GenericSummaryProps) => {
  const { title, fieldsCollection } = props;
  return (
    <div style={{ display: 'block' }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
        {fieldsCollection.map(([label, value], idx) => {
          return (
            <div key={`tb-field-${idx}`} style={{ color: '#555' }}>
              <span style={{ display: 'inline-block', minWidth: '130px', fontWeight: 'bold' }}>{label}: </span>
              <span>{value}</span>
            </div>
          );
        })}
      </Box>
    </div>
  );
};

export default GenericSummary;
