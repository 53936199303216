import React, { ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from '../resources/routes-constants';
import { selectAccessToken } from '../states/auth-slices';
import { useAppSelector } from '../states/store';

export const ProtectedRoute = ({ children }: { children: ReactElement }) => {
  const token = useAppSelector(selectAccessToken);
  const location = useLocation();

  if (!token) {
    return <Navigate to={ROUTES.LOGIN_ROUTE} replace state={{ from: location.pathname || ROUTES.ACCOUNTS_ROUTE }} />;
  }

  if (token && location.pathname === ROUTES.HOMEPAGE_ROUTE) {
    return <Navigate to={ROUTES.ACCOUNTS_ROUTE} replace />;
  }

  return children;
};
