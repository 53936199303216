import { ENVIRONMENT } from '../utility/functions';

export const ACCOUNTS_API_PATH = '/users/accounts';
export const PROFILES_API_PATH = '/users/profiles';
export const GOOGLE_SIGN_IN_PATH = '/auth/kolibree/google';
export const BRUSHINGS_API_PATH = '/brushings';
export const TOOTHBRUSHES_API_PATH = '/toothbrushes';

export const getProfileGGTApiPath = (profileId: string) => `${PROFILES_API_PATH}/${profileId}/gamification-goals/trackers`;
export const getProfileRewardsApiPath = (profileId: string) => `${PROFILES_API_PATH}/${profileId}/rewards`;
export const getAccountApiPath = (accountId: string) => `${ACCOUNTS_API_PATH}/${accountId}`;
export const getProfileApiPath = (profileId: string) => `${PROFILES_API_PATH}/${profileId}`;
export const getAccountProfilesApiPath = (accountId: string) =>
  `${PROFILES_API_PATH}?filter[field_path]=user_account.id&filter[value]=${accountId}&filter[type]=String`;
export const getToothbrushApiPath = (tbId: string) => `${TOOTHBRUSHES_API_PATH}/${tbId}`;
export const getProfileBrushingsApiPath = (profileId: string) =>
  `${BRUSHINGS_API_PATH}?filter[field_path]=user_profile.id&filter[value]=${profileId}&filter[type]=String`;

enum CLIENT_CREDENTIAL_KEY {
  CLIENT_ID = 'CLIENT_ID',
  API_HOSTNAME = 'API_HOSTNAME',
  GOOGLE_CLIENT_ID = 'GOOGLE_CLIENT_ID',
}

export const CLIENT_CREDENTIAL = {
  [ENVIRONMENT.DEVELOPMENT]: {
    [CLIENT_CREDENTIAL_KEY.CLIENT_ID]: process.env.REACT_APP_DEV_API_CLIENT_ID!,
    [CLIENT_CREDENTIAL_KEY.API_HOSTNAME]: process.env.REACT_APP_DEV_API_HOSTNAME!,
    [CLIENT_CREDENTIAL_KEY.GOOGLE_CLIENT_ID]: process.env.REACT_APP_DEV_GOOGLE_CLIENT_ID!,
  },
  [ENVIRONMENT.STAGING]: {
    [CLIENT_CREDENTIAL_KEY.CLIENT_ID]: process.env.REACT_APP_STAGING_API_CLIENT_ID!,
    [CLIENT_CREDENTIAL_KEY.API_HOSTNAME]: process.env.REACT_APP_STAGING_API_HOSTNAME!,
    [CLIENT_CREDENTIAL_KEY.GOOGLE_CLIENT_ID]: process.env.REACT_APP_STAGING_GOOGLE_CLIENT_ID!,
  },
  [ENVIRONMENT.PRODUCTION]: {
    [CLIENT_CREDENTIAL_KEY.CLIENT_ID]: process.env.REACT_APP_PROD_API_CLIENT_ID!,
    [CLIENT_CREDENTIAL_KEY.API_HOSTNAME]: process.env.REACT_APP_PROD_API_HOSTNAME!,
    [CLIENT_CREDENTIAL_KEY.GOOGLE_CLIENT_ID]: process.env.REACT_APP_PROD_GOOGLE_CLIENT_ID!,
  },
} as const;
