import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridToolbarFilterButton,
  GridToolbarContainer,
  getGridStringOperators,
  GridSortModel,
} from '@mui/x-data-grid';
import { Snackbar } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useGetResourcesQuery } from '../apis';
import { ListBaseRequest, StringFilter, FilterType, OrderBy } from '../apis/types';

/**
 * Resource list is a smart table built on the top of MUI data grid, provides a set of
 * features for displaying data. Since our REST APIs have the same query patterns, we
 * could leverage this trait to work with datasets.
 */
interface ResourceListProps {
  apiPath: string;
  columns: GridColDef[];
}

export default function ResourceList(props: ResourceListProps) {
  const { apiPath, columns: rawColumns } = props;
  const [pageInfo, setPageInfo] = useState<GridPaginationModel>({ page: 0, pageSize: 10 });
  const [sort, setSort] = useState<ListBaseRequest['sort']>([]);
  const [filter, setFilter] = useState<ListBaseRequest['filter']>([]);

  const {
    data: { resources, pagination } = {},
    isLoading,
    isFetching,
    isError,
  } = useGetResourcesQuery({
    path: apiPath,
    params: { pageSize: pageInfo.pageSize, pageIndex: pageInfo.page + 1, sort, filter },
  });

  const handleFilterModelChange = (filterModel: GridFilterModel) => {
    // FIXME - to implement more filter
    const filter = filterModel.items.filter(({ value }) => !!value);

    setFilter(
      filter.length
        ? filter.map(({ field, value }) => {
            return {
              fieldPath: field,
              value,
              type: FilterType.String,
            } as StringFilter;
          })
        : [],
    );
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    const sort = sortModel.map((sortItem) => ({
      fieldPath: sortItem.field,
      order: sortItem.sort === 'asc' ? OrderBy.ASCENDING : OrderBy.DESCENDING,
    }));
    setSort(sort);
  };

  const [columns, filterable] = useMemo(() => {
    const cols = rawColumns.map<GridColDef>(({ filterable, type, ...rest }) => ({
      sortable: false,
      filterable: filterable ?? false,
      hideable: false,
      type,
      ...rest,
      ...(filterable && type === 'string' && { filterOperators: getGridStringOperators().filter((ope) => ope.value === 'contains') }),
    }));
    const filterable = cols.some((col) => col.filterable);
    return [cols, filterable];
  }, [rawColumns]);

  if (isError) {
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isError} message="Failed" />;
  }

  return (
    <>
      <DataGrid
        autoHeight
        columns={columns}
        rows={resources ? resources : []}
        rowCount={pagination ? pagination.totalItemsCount : 0}
        sx={{ '--DataGrid-overlayHeight': '300px' }}
        slots={{
          toolbar: filterable ? CustomToolbar : null,
        }}
        loading={isLoading || isFetching}
        pageSizeOptions={[10, 20]}
        paginationModel={{ ...pageInfo, ...(pagination ?? {}) }}
        paginationMode="server"
        onPaginationModelChange={setPageInfo}
        filterMode="server"
        onFilterModelChange={handleFilterModelChange}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
      />
    </>
  );
}

interface CustomToolbarProps {
  setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
}

function CustomToolbar({ setFilterButtonEl }: CustomToolbarProps) {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton ref={setFilterButtonEl} />
    </GridToolbarContainer>
  );
}
