import React from 'react';
import { Box } from '@mui/material';
import Navigation from './Navigation';
import UserAvatar from './UserAvatar';
import Logo from './Logo';
import { ROUTES } from '../resources/routes-constants';
import Link from './Link';

const NavBar: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '16px',
        height: '100vh',
        alignItems: 'center',
        background: '#313B48',
      }}
    >
      <Link to={ROUTES.HOMEPAGE_ROUTE} style={{ height: '40px', marginBottom: '40px' }}>
        <Logo />
      </Link>
      <div style={{ flex: 1 }}>
        <Navigation />
      </div>
      <div style={{ height: '40px' }}>
        <UserAvatar />
      </div>
    </Box>
  );
};

export default NavBar;
