import { Profile, RewardType, Rewards } from './types';
import { api } from './index';
import { getProfileApiPath, getProfileRewardsApiPath } from '../resources/api-constants';

const profileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<Profile, string>({
      query: (id) => ({
        url: getProfileApiPath(id),
        method: 'get',
      }),
    }),
    getRewardsByProfileId: builder.query<{ smilePoint: number; diamond: number }, string>({
      query: (id) => ({
        url: getProfileRewardsApiPath(id),
        method: 'get',
      }),
      transformResponse: ({ available }: Rewards) => {
        let smilePoint = 0;
        let diamond = 0;
        available.forEach(({ type, amount }) => {
          if (type === RewardType.Diamonds) {
            diamond += amount;
          } else {
            smilePoint += amount;
          }
        });
        return {
          smilePoint,
          diamond,
        };
      },
    }),
  }),
});

export const { useGetProfileQuery, useGetRewardsByProfileIdQuery } = profileApi;
