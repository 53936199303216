import React from 'react';
import { UserIcon } from '@baracoda/albatross.ui.icons.user-icon';
import { EXTERNAL_ROUTE, ROUTES } from '../resources/routes-constants';
import { useLocation } from 'react-router-dom';
import Link from './Link';

const Navigation: React.FC = () => {
  const location = useLocation();
  const navigationInfo = [
    { route: ROUTES.ACCOUNTS_ROUTE, icon: <UserIcon color="#fff" />, text: 'Accounts' },
    { route: ROUTES.TOOTHBRUSHES_ROUTE, icon: null, text: 'Toothbrushes' },
    // FIXME: the url will be depending on env
    { route: EXTERNAL_ROUTE.DOCUMENT, icon: null, text: 'Documentation', target: '_blank' },
  ];
  return (
    <nav
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        alignItems: 'center',
      }}
    >
      {navigationInfo.map((nav, idx) => (
        <Link to={nav.route} color="inherit" key={`nav-${idx}`} underline="none" sx={{ width: '100%' }} target={nav.target ?? '_self'}>
          <div
            style={{
              display: 'flex',
              alignSelf: 'center',
              justifyContent: 'center',
              background: location.pathname.includes(nav.route) ? '#0068fd' : 'transparent',
              borderRadius: '4px',
              padding: '0 10px',
              height: '40px',
              lineHeight: '40px',
              color: '#fff',
            }}
          >
            {nav.text}
          </div>
        </Link>
      ))}
    </nav>
  );
};

export default Navigation;
