import axios, { AxiosError } from 'axios';
import { toCamelCase, toSnackCase } from './functions';

const CustomAxios = axios.create({
  timeout: 5000,
});

CustomAxios.interceptors.response.use(
  (response) => {
    if (response.status >= 300 && response.status < 400 && response.headers.location) {
      const originalRequestConfig = response.config;
      originalRequestConfig.url = response.headers.location;
      return axios(originalRequestConfig);
    }
    return response;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

CustomAxios.interceptors.response.use(
  (response) => {
    response.data = toCamelCase(response.data);
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

CustomAxios.interceptors.request.use(
  (config) => {
    config.data = toSnackCase(config.data);
    config.params = toSnackCase(config.params);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// token expired interceptor
// const unauthorizedInterceptor = CustomAxios.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     if (error.response.status === 401) {
//       CustomAxios.interceptors.response.eject(unauthorizedInterceptor);
//       try {
//         const rs = await refreshToken();
//         setItem('session', rs);
//
//         return CustomAxios(error.response.config);
//       } catch (err) {
//         return Promise.reject(err);
//       }
//     }
//     return Promise.reject(error);
//   },
// );

export default CustomAxios;
