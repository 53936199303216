import { useParams } from 'react-router-dom';

/**
 * Note:
 * The original behaviour of `useParams` would make every route params optional.
 * A common convention is that path/route params are mandatory while query params are
 * optional. It's also not a good idea to make casts everywhere with `useParams`.
 */
export const useRequiredParams = <T extends Record<string, string>>() => {
  const params = useParams<T>();
  return params as T;
};
