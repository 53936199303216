export const ROUTES = {
  HOMEPAGE_ROUTE: '/',
  ACCOUNTS_ROUTE: '/accounts',
  ACCOUNT_DETAIL_ROUTE: '/accounts/:accountId',
  PROFILE_DETAIL_ROUTE: '/profiles/:profileId',
  LOGIN_ROUTE: '/login',
  TOOTHBRUSHES_ROUTE: '/toothbrushes',
  TOOTHBRUSH_DETAIL_ROUTE: '/toothbrushes/:tbId',
  BRUSHINGS_ROUTE: 'toothbrushes/:id/brushings',
  DASHBOARD_ROUTE: '/dashboard',
};

export const EXTERNAL_ROUTE = {
  DOCUMENT: process.env.NODE_ENV !== 'development' ? '/api/v1/docs' : 'https://api.p.kolibree.com/oas/api/v1/docs',
};

export const getProfileDetailRoute = (profileId: string) => {
  return `/profiles/${profileId}`;
};

export const getAccountDetailRoute = (accountId: string) => {
  return `/accounts/${accountId}`;
};

export const getBrushingsRoute = (tbId: string) => {
  return `/toothbrushes/${tbId}/brushings`;
};

export const getToothbrushDetailRoute = (tbId: string) => {
  return `/toothbrushes/${tbId}`;
};
