import React from 'react';
import { Paper, Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { getProfileDetailRoute } from '../resources/routes-constants';
import { getAccountProfilesApiPath } from '../resources/api-constants';
import { AccountRoute } from '../types/route-param';
import Link from '../components/Link';
import { useGetAccountQuery } from '../apis/account';
import ResourceList from '../components/ResourceList';
import { Profile } from '../apis/types';
import { useRequiredParams } from '../hooks/useRequiredParams';
import AccountSummary from '../components/AccountSummary';
import Skeleton from '../components/Skeleton';
import { PROFILE_DETAIL_LABELS } from '../resources/text-constants';

const AccountDetailPage: React.FC = () => {
  const { accountId } = useRequiredParams<AccountRoute>();
  const { isLoading, data: accountDetail } = useGetAccountQuery(accountId);
  const spans = accountDetail?.parentalConsent ? 3 : 4;

  const profileColumns: GridColDef<Profile>[] = [
    {
      field: 'firstName',
      headerName: PROFILE_DETAIL_LABELS.firstName,
      width: 400,
      renderCell: ({ value, row }) => <Link to={getProfileDetailRoute(row.id)}>{value}</Link>,
    },
    {
      field: 'creationTimestamp',
      headerName: PROFILE_DETAIL_LABELS.creationTimestamp,
      type: 'date',
      width: 400,
      valueGetter: ({ value }) => new Date(value * 1000),
      valueFormatter: ({ value }) => moment(value).format(),
    },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
      <div>
        <Typography variant="h4" gutterBottom>
          Account Detail
        </Typography>
        {!isLoading ? (
          <Paper sx={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={spans}>
                <AccountSummary
                  title="Basic"
                  data={accountDetail!}
                  fields={['friendlyId', 'email', 'isBeta', 'creationTimestamp', 'lastUpdateTimestamp', 'deprecatedId', 'referralCode']}
                />
              </Grid>
              <Grid item xs={spans}>
                <AccountSummary data={accountDetail!} title="Owner User Profile" fields={['ownerUserProfile.id', 'ownerUserProfile.deprecatedId']} />
              </Grid>
              <Grid item xs={spans}>
                <AccountSummary
                  data={accountDetail!}
                  title="Account Settings"
                  fields={['settings.countryCode', 'settings.firstDayOfWeek', 'settings.languageCode', 'settings.timezone']}
                />
              </Grid>
              {accountDetail?.parentalConsent ? (
                <Grid item xs={spans}>
                  <AccountSummary data={accountDetail!} title="Parental Consent" fields={['parentalConsent.approvedAt', 'parentalConsent.email']} />
                </Grid>
              ) : null}
            </Grid>
          </Paper>
        ) : (
          <Box sx={{ height: '264px' }}>
            <Skeleton />
          </Box>
        )}
      </div>
      <div>
        <Typography variant="h4" gutterBottom>
          Profiles
        </Typography>
        <ResourceList columns={profileColumns} apiPath={getAccountProfilesApiPath(accountId)} />
      </div>
    </Box>
  );
};

export default AccountDetailPage;
