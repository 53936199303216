import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { Brushing } from '../apis/types';
import { BRUSHING_DETAIL_LABELS } from '../resources/text-constants';
import { formatDuration } from '../utility/functions';
import moment from 'moment';
import { Typography } from '@mui/material';
import ResourceList from './ResourceList';
import React from 'react';
import { getProfileBrushingsApiPath } from '../resources/api-constants';

export default function BrushingList(props: { profileId: string }) {
  const columns: GridColDef<Brushing>[] = [
    {
      field: 'activity',
      headerName: BRUSHING_DETAIL_LABELS['activity.type'],
      width: 200,
      renderCell: ({ value }) => value.type,
    },
    {
      field: 'metrics',
      headerName: BRUSHING_DETAIL_LABELS['metrics.durationInMilliseconds'],
      width: 200,
      valueFormatter: ({ value }: GridValueFormatterParams<Brushing['metrics']>) => formatDuration(value.durationInMilliseconds / 1000),
    },
    {
      field: 'createdByUserAt',
      headerName: BRUSHING_DETAIL_LABELS.createdByUserAt,
      width: 500,
      type: 'date',
      valueFormatter: ({ value }) => moment(value).format(),
    },
    {
      field: 'creationTimestamp',
      headerName: BRUSHING_DETAIL_LABELS.creationTimestamp,
      width: 500,
      type: 'date',
      valueGetter: ({ value }) => new Date(value * 1000),
      valueFormatter: ({ value }) => moment(value).format(),
    },
  ];
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Brushings
      </Typography>
      <ResourceList columns={columns} apiPath={getProfileBrushingsApiPath(props.profileId)} />
    </div>
  );
}
