import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { GoogleLogin, GoogleLoginProps } from '@react-oauth/google';
import { ROUTES } from '../resources/routes-constants';
import { useGoogleSignInMutation } from '../apis/auth';
import { CircularProgress, Snackbar } from '@mui/material';
import { useAppSelector } from '../states/store';
import { selectAccessToken } from '../states/auth-slices';

const LoginPage: React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const token = useAppSelector(selectAccessToken);

  const [signInGoogle, { isLoading, isError, isSuccess }] = useGoogleSignInMutation();

  const handleGoogleSignIn: GoogleLoginProps['onSuccess'] = (response) => {
    signInGoogle(response.credential!);
  };

  if (token) {
    return <Navigate to={ROUTES.HOMEPAGE_ROUTE} />;
  }

  if (isSuccess) {
    navigate(state?.from || ROUTES.HOMEPAGE_ROUTE);
  }

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '400px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <GoogleLogin
        ux_mode="popup"
        onSuccess={handleGoogleSignIn}
        onError={() => {
          console.log('Login Failed');
        }}
      />
      {isLoading && <CircularProgress />}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isError} message="Login failed" />
    </div>
  );
};

export default LoginPage;
