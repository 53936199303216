
// Toothbrush
import type { ProfileDB } from './profile';
import { ListBaseResponseDB } from './common';

export type ToothbrushDB = ConnectedToothbrush | MagikToothbrush | OtherToothbrush;

export type ToothbrushDBList = ListBaseResponseDB<ToothbrushDB>;

export interface ConnectedToothbrush extends BaseToothbrush {
  firmware_version: string;
  hardware_version: string;
  head:             BrushHead;
  mac_address:      string;
  name:             string;
  serial_number:    string;
}

export interface MagikToothbrush extends BaseToothbrush {
  color: ToothbrushColor;
}

export type OtherToothbrush = BaseToothbrush;

interface BaseToothbrush {
  id:               string;
  link_self:        string;
  deprecated_id:    number;
  type:             ToothbrushType;
  model:            string;
  user_profile:     Pick<ProfileDB, 'id' | 'link_self' | 'deprecated_id'>;
}


export enum ToothbrushType {
  Connected = 'Connected',
  Magik = 'Magik',
  Other = 'Other'
}

export enum ToothbrushColor {
  RED = 'RED',
  YELLOW = 'YELLOW'
}

interface BrushHead {
  last_replaced_at: Date;
}