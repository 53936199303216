import React, { ReactElement } from 'react';
import { Toothbrush } from '../apis/types';
import Link from './Link';
import { getProfileDetailRoute } from '../resources/routes-constants';
import GenericSummary from './GenericSummary';
import { useDetailFormat } from '../hooks/useDetailFormat';
import { NestedKeyOf } from '../types/utils';
import { TOOTHBRUSH_DETAIL_LABELS } from '../resources/text-constants';

interface ToothbrushSummaryProps {
  title: string;
  fields: NestedKeyOf<Toothbrush>[];
  data: Toothbrush;
}

const ToothbrushSummary: React.FC<ToothbrushSummaryProps> = (props) => {
  const { title, fields, data } = props;
  const fieldValueMap = useDetailFormat(data, fields).map(([fieldName, value]) => {
    let customNode;
    if (fieldName === 'userProfile.id') {
      customNode = <Link to={getProfileDetailRoute(String(value))}>{value}</Link>;
    } else {
      customNode = <span>{value}</span>;
    }
    return [TOOTHBRUSH_DETAIL_LABELS[fieldName], customNode];
  }) as [string, ReactElement][];

  return <GenericSummary title={title} fieldsCollection={fieldValueMap} />;
};

export default ToothbrushSummary;
