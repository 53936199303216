import React from 'react';
import { Box, SvgIcon } from '@mui/material';

const Logo = () => {
  return (
    <Box sx={{ display: 'flex', width: 40, height: 40, justifyContent: 'center', alignItems: 'center' }}>
      <SvgIcon>
        <svg xmlns="http://www.w3.org/2000/svg" width="385" height="456" viewBox="0 0 385 456" fill="none">
          <path
            d="M222.19 132.302C151.91 132.302 91.5 177.772 69.27 240.922V0.771606H0V290.172H60.22C60.22 291.552 60.16 292.932 60.16 294.322C60.16 383.212 133.3 455.942 222.16 455.542C311.45 455.132 384.16 383.212 384.16 294.322C384.21 205.032 311.48 132.302 222.19 132.302ZM222.19 388.062C170.87 388.062 129.26 346.062 129.26 295.132C129.26 270.485 139.051 246.848 156.479 229.42C173.906 211.992 197.543 202.202 222.19 202.202C246.837 202.202 270.474 211.992 287.901 229.42C305.329 246.848 315.12 270.485 315.12 295.132C315.12 346.042 273.5 388.062 222.19 388.062Z"
            fill="white"
          />
        </svg>
      </SvgIcon>
    </Box>
  );
};

export default Logo;
