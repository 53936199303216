import moment from 'moment';

/**
 * Format duration in seconds to a humanized string with `A years B months C days D hours E minutes F seconds`.
 * The unit will be sensitive to singular and plural.
 * @param {number} durationInSeconds
 * @returns {string}
 */
export function formatDuration(durationInSeconds: number) {
  const duration = moment.duration(durationInSeconds, 'seconds');
  const unitValues = [duration.years(), duration.months(), duration.days(), duration.hours(), duration.minutes(), duration.seconds()];
  const unit = ['year', 'month', 'day', 'hour', 'minute', 'second'];

  return unitValues
    .reduce((formattedDurations, unitValue, currentIndex) => {
      if (unitValue > 0) {
        formattedDurations.push(`${unitValue} ${unit[currentIndex]}${unitValue === 1 ? '' : 's'}`);
      }
      return formattedDurations;
    }, [] as string[])
    .join(' ');
}

/**
 * convert camel case object to snake case
 * @param object
 * @returns {any}
 */
export const toSnackCase: any = (object: Record<string, unknown> | Array<unknown>) => {
  let transformedObject = object;
  if (typeof object === 'object' && object !== null) {
    if (object instanceof Array) {
      transformedObject = object.map(toSnackCase);
    } else {
      transformedObject = {};
      for (const key in object) {
        if (object[key] !== undefined) {
          const newKey = key
            .replace(/\.?([A-Z]+)/g, function (_, y) {
              return '_' + y.toLowerCase();
            })
            .replace(/^_/, '');
          transformedObject[newKey] = toSnackCase(object[key]);
        }
      }
    }
  }
  return transformedObject;
};

/**
 * Convert snake case object or array to camel case
 * @param object
 * @returns {any}
 */
export const toCamelCase: any = (object: Record<string, unknown> | Array<unknown>) => {
  let transformedObject = object;
  if (typeof object === 'object' && object !== null) {
    if (object instanceof Array) {
      transformedObject = object.map(toCamelCase);
    } else {
      transformedObject = {};
      for (const key in object) {
        if (object[key] !== undefined) {
          const newKey = key.replace(/(_\w)|(-\w)/g, (k) => k[1].toUpperCase());
          transformedObject[newKey] = toCamelCase(object[key]);
        }
      }
    }
  }
  return transformedObject;
};

/**
 * Convert any string to capitalized string and enable to customize delimiter
 * @param {string} str
 * @param sourceFormat
 * @param delimiter
 */
export const toCapitalizedStr = (str: string, sourceFormat: 'camelcase' = 'camelcase', delimiter = ''): string => {
  if (sourceFormat === 'camelcase') {
    return str.replace(/([A-Z])/g, `${delimiter}$1`).replace(/^./, function (str) {
      return str.toUpperCase();
    });
  }
  return str;
};

export enum ENVIRONMENT {
  PRODUCTION = 'production',
  DEVELOPMENT = 'development',
  STAGING = 'staging',
}

export const getEnv = () => {
  const { hostname } = window.location;

  if (hostname.includes('app.s.kolibree.com')) {
    return ENVIRONMENT.STAGING;
  }

  if (hostname.includes('app.kolibree.com') || hostname.includes('app.p.kolibree.com')) {
    return ENVIRONMENT.PRODUCTION;
  }

  return ENVIRONMENT.DEVELOPMENT;
};

export const sleep = (second: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, second * 1000);
  });
};
