export enum RewardType {
  Diamonds = 'Diamonds',
  SmilePoint = 'SmilePoint',
}

export interface Rewards {
  available: Array<{ type: RewardType; amount: number }>;
}

export interface Reward {
  type: RewardType;
  amount: number;
}
