import React from 'react';
import { getProfileDetailRoute, getToothbrushDetailRoute } from '../resources/routes-constants';
import Link from '../components/Link';
import ResourceList from '../components/ResourceList';
import { GridColDef } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { Toothbrush } from '../apis/types';
import { TOOTHBRUSH_DETAIL_LABELS } from '../resources/text-constants';
import { TOOTHBRUSHES_API_PATH } from '../resources/api-constants';

const ToothbrushesPage: React.FC = () => {
  const toothbrushColumns: GridColDef<Toothbrush>[] = [
    {
      field: 'type',
      headerName: TOOTHBRUSH_DETAIL_LABELS.type,
      width: 300,
      renderCell: ({ value, row }) => <Link to={getToothbrushDetailRoute(row.id)}>{value}</Link>,
    },
    {
      field: 'model',
      headerName: TOOTHBRUSH_DETAIL_LABELS.model,
      width: 300,
    },
    {
      field: 'userProfile',
      headerName: TOOTHBRUSH_DETAIL_LABELS.userProfile,
      width: 300,
      renderCell: ({ value }) => <Link to={getProfileDetailRoute(value.id)}>{value.id}</Link>,
    },
    {
      field: 'serialNumber',
      filterable: true,
      headerName: TOOTHBRUSH_DETAIL_LABELS.serialNumber,
      width: 300,
      renderCell: ({ value }) => value || '-',
    },
    {
      field: 'macAddress',
      headerName: TOOTHBRUSH_DETAIL_LABELS.macAddress,
      width: 400,
      renderCell: ({ value }) => value || '-',
    },
  ];
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Toothbrushes
      </Typography>
      <ResourceList apiPath={TOOTHBRUSHES_API_PATH} columns={toothbrushColumns} />
    </div>
  );
};

export default ToothbrushesPage;
