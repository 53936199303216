import { ListBaseRequest, Toothbrush, ToothbrushList } from './types';
import { api } from './index';
import { getToothbrushApiPath, TOOTHBRUSHES_API_PATH } from '../resources/api-constants';

const toothbrushApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // FIXME
    getToothbrushes: builder.query<ToothbrushList, Partial<ListBaseRequest & { profileId: string }>>({
      query: (params) => ({
        url: TOOTHBRUSHES_API_PATH,
        method: 'get',
        params,
      }),
    }),
    getToothbrush: builder.query<Toothbrush, string>({
      query: (id: string) => ({ url: getToothbrushApiPath(id), method: 'get' }),
    }),
  }),
});

export const { useGetToothbrushesQuery, useGetToothbrushQuery } = toothbrushApi;
