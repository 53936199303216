export interface BaseReminderDB {
  is_active: boolean;
}

export interface PaginationMetaDB {
  total_items_count: number;
  page_index: number;
  page_size: number;
  total_pages: number;
}

export interface SortItemDB {
  field_path: string;
  order?: OrderBy
}

export interface StringFilterDB {
  field_path: string;
  type: FilterType.String;
  operator?: 'CONTAINS_IGNORE_CASE';
  value: string;
}

export interface NumberFilterDB {
  field_path: string;
  type: FilterType.Number;
  operator?: NumberFilterOperator;
  value: number;
}

export interface DateTimeFilterDB {
  field_path: string;
  type: FilterType.DateTime;
  operator?: DateTimeFilterOperator;
  value: string; // ISO-8601
}

export interface DateTimeRangeFilterDB {
  field_path: string;
  type: FilterType.DateTimeRange;
  operator?: 'BETWEEN';
  end_value: string;
  start_value: string;
}

export type FilterItemDB = NumberFilterDB | StringFilterDB | DateTimeFilterDB | DateTimeRangeFilterDB;

export enum OrderBy {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING'
}

export enum NumberFilterOperator {
  EQUALS = 'EQUALS',
  LOWER_THAN = 'LOWER_THAN',
  LOWER_OR_EQUAL_THAN = 'LOWER_OR_EQUAL_THAN',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_OR_EQUAL_THAN = 'GREATER_OR_EQUAL_THAN',
}

type DateTimeFilterOperator = NumberFilterOperator

export enum FilterType {
  String='String',
  Number='Number',
  DateTime='DateTime',
  DateTimeRange='DateTimeRange',
}

export enum DayOfWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum Gender {
  Male = 'M',
  Female = 'F',
  Unknown = 'U',
  NotCommunicated = 'NC',
  GenderNonConforming = 'GNC'
}

export interface ListBaseResponseDB<Data> {
  pagination: PaginationMetaDB;
  resource: Data[]
}

export type ListBaseRequestDB = Omit<PaginationMetaDB, 'total_items_count' | 'total_pages'> & Partial<{ sort: SortItemDB[]; filter: FilterItemDB[] }>