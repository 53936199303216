import { GoogleSignInResponse } from './types';
import { GOOGLE_SIGN_IN_PATH } from '../resources/api-constants';
import { api } from './index';

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    googleSignIn: builder.mutation<GoogleSignInResponse, string>({
      query: (idToken: string) => {
        return {
          url: GOOGLE_SIGN_IN_PATH,
          method: 'post',
          data: { idToken },
        };
      },
    }),
  }),
});

export const { useGoogleSignInMutation } = authApi;
