import React from 'react';
import moment from 'moment';
import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { getAccountDetailRoute } from '../resources/routes-constants';
import Link from '../components/Link';
import ResourceList from '../components/ResourceList';
import { Account } from '../apis/types';
import { ACCOUNTS_API_PATH } from '../resources/api-constants';
import { ACCOUNT_DETAIL_LABELS } from '../resources/text-constants';

const AccountsPage: React.FC = () => {
  const accountColumns: GridColDef<Account>[] = [
    {
      field: 'friendlyId',
      headerName: ACCOUNT_DETAIL_LABELS.friendlyId,
      width: 200,
      renderCell: ({ value, row }) => <Link to={getAccountDetailRoute(row.id)}>{value}</Link>,
    },
    {
      field: 'email',
      headerName: ACCOUNT_DETAIL_LABELS.email,
      filterable: true,
      sortable: true,
      type: 'string',
      width: 350,
      valueFormatter: ({ value }) => value || '-',
    },
    {
      field: 'isBeta',
      headerName: ACCOUNT_DETAIL_LABELS.isBeta,
      width: 200,
      valueFormatter: ({ value }) => (value ? 'Y' : 'N'),
    },
    {
      field: 'creationTimestamp',
      headerName: ACCOUNT_DETAIL_LABELS.creationTimestamp,
      width: 500,
      type: 'date',
      valueGetter: ({ value }) => new Date(value * 1000),
      valueFormatter: ({ value }) => moment(value).format(),
    },
  ];
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Accounts
      </Typography>
      <ResourceList apiPath={ACCOUNTS_API_PATH} columns={accountColumns} />
    </div>
  );
};

export default AccountsPage;
