import React from 'react';
import moment from 'moment';
import { Table, TableBody, TableHead, TableRow, TableCell, TableHeaderCell } from '@baracoda/albatross.ui.data-display.table';
import { Paper } from '@mui/material';
import { ThemeUIProvider } from 'theme-ui';
import { albatrossBaseTheme } from '@baracoda/albatross.ui.themes.base-theme';
import { useParams } from 'react-router-dom';
import { getProfileDetailRoute } from '../resources/routes-constants';
import { BrushingRoute } from '../types/route-param';
import Link from '../components/Link';

const BrushingsPage: React.FC = () => {
  const { tbSerialNumber } = useParams<BrushingRoute>();
  const timestamp = moment().format();
  const mockData = [
    { id: 1, type: 'guided brushing', account_id: 12, duration: 90, serial_number: 'abc', profile_id: 12, created_time: timestamp, recorded_time: timestamp },
  ];
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Paper>Toothbrush SN: {tbSerialNumber}</Paper>
      <div>
        <ThemeUIProvider theme={albatrossBaseTheme}>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell align="center" width="100px">
                  Brushing ID
                </TableHeaderCell>
                <TableHeaderCell>Activity Type</TableHeaderCell>
                <TableHeaderCell align="center" width="300px">
                  Duration
                </TableHeaderCell>
                <TableHeaderCell align="center" width="300px">
                  Toothbrush SN
                </TableHeaderCell>
                <TableHeaderCell align="center" width="300px">
                  Profile
                </TableHeaderCell>
                <TableHeaderCell align="center" width="500px">
                  Created by user at
                </TableHeaderCell>
                <TableHeaderCell align="center">Recorded by BE at</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mockData.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell align={'left'}>{item.type}</TableCell>
                  <TableCell>{item.duration}</TableCell>
                  <TableCell>{item.serial_number}</TableCell>
                  <TableCell>
                    <Link to={getProfileDetailRoute(String(item.profile_id))}>{item.profile_id}</Link>
                  </TableCell>
                  <TableCell>{item.created_time}</TableCell>
                  <TableCell>{item.recorded_time}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ThemeUIProvider>
      </div>
    </div>
  );
};

export default BrushingsPage;
