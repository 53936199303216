import React, { ReactElement } from 'react';
import { ProfileRoute } from '../types/route-param';
import { Box, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useGetProfileQuery, useGetRewardsByProfileIdQuery } from '../apis/profile';
import { toCapitalizedStr } from '../utility/functions';
import { useRequiredParams } from '../hooks/useRequiredParams';
import { useGetToothbrushesQuery } from '../apis/toothbrush';
import ProfileSummary from '../components/Profile/ProfileSummary';
import get from 'lodash.get';
import GenericSummary from '../components/GenericSummary';
import Skeleton from '../components/Skeleton';
import Link from '../components/Link';
import { getToothbrushDetailRoute } from '../resources/routes-constants';
import { TOOTHBRUSH_DETAIL_LABELS } from '../resources/text-constants';
import BrushingList from '../components/BrushingList';
import ProfileGamificationGoalTracker from '../components/Profile/GamificationGoalTracker';

const ProfileDetailPage: React.FC = () => {
  const { profileId } = useRequiredParams<ProfileRoute>();
  const { isSuccess, data: profileDetail } = useGetProfileQuery(profileId);
  const { data: rewards = {} } = useGetRewardsByProfileIdQuery(profileId);
  const { data: { resources: [pairedToothbrush] = [] } = {} } = useGetToothbrushesQuery({ profileId });

  const availableField = (['serialNumber', 'model', 'id'] as const).find((fieldName) => get(pairedToothbrush || {}, fieldName));
  const rewardLabels = Object.entries(rewards).map(([key, value]) => [toCapitalizedStr(key, 'camelcase', ' '), value] as [string, number]);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
      <div>
        <Typography variant="h4" gutterBottom>
          Profile Detail
        </Typography>
        {isSuccess ? (
          <Paper sx={{ padding: '20px' }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <ProfileSummary
                  title="Basic"
                  fields={[
                    'id',
                    'deprecatedId',
                    'creationTimestamp',
                    'lastUpdateTimestamp',
                    'age.birthday',
                    'age.bucket',
                    'firstName',
                    'lastName',
                    'isUserAccountOwner',
                    'gender',
                  ]}
                  data={profileDetail!}
                />
              </Grid>
              <Grid item container xs={4}>
                <Grid item xs={12}>
                  <ProfileSummary title="Metadata" fields={['metadata.createdFromApplication']} data={profileDetail!} />
                </Grid>
                <Grid item xs={12}>
                  <ProfileSummary title="Settings" fields={['settings.handedness']} data={profileDetail!} />
                </Grid>
                <Grid item xs={12}>
                  <ProfileSummary title="User Account" fields={['userAccount.id', 'userAccount.deprecatedId']} data={profileDetail!} />
                </Grid>
              </Grid>
              <Grid item container xs={4}>
                <Grid item xs={12}>
                  <GenericSummary title="Reward" fieldsCollection={rewardLabels} />
                </Grid>
                <Grid item xs={12}>
                  {availableField ? (
                    <GenericSummary
                      title="Paired Toothbrush"
                      fieldsCollection={
                        [
                          [
                            TOOTHBRUSH_DETAIL_LABELS[availableField],
                            <Link key="toothbrush-link" to={getToothbrushDetailRoute(pairedToothbrush.id)}>
                              {pairedToothbrush[availableField] || '-'}
                            </Link>,
                          ],
                        ] as [string, ReactElement][]
                      }
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <Skeleton />
        )}
      </div>
      <ProfileGamificationGoalTracker profileId={profileId} />
      <BrushingList profileId={profileId} />
    </Box>
  );
};

export default ProfileDetailPage;
