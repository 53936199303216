import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { ListBaseRequest, ListBaseResponse } from './types';
import { AnyObject } from '../types/utils';
import { axiosBaseQuery } from './base-query';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({ baseUrl: process.env.NODE_ENV === 'development' ? '/oas/api/v1' : '/api/v1' }),
  endpoints: () => ({}),
});

const genericFetchApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getResources: builder.query<ListBaseResponse<AnyObject>, { path: string; params: ListBaseRequest }>({
      query: ({ path, params }) => ({
        url: path,
        method: 'get',
        params,
      }),
    }),
  }),
});

export const { useGetResourcesQuery } = genericFetchApi;
