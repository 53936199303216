import { AxiosError, AxiosRequestConfig } from 'axios';
import { BaseQueryFn, BaseQueryApi } from '@reduxjs/toolkit/query/react';
import snakeCase from 'lodash.snakecase';
import axios from '../utility/custom-axios';
import { RootState } from '../states/store';
import { getEnv, toSnackCase } from '../utility/functions';
import { CLIENT_CREDENTIAL } from '../resources/api-constants';
import { ListBaseRequestDB } from './types';
import { SortItemDB, StringFilterDB } from './types/db/common';

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' },
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    {
      status: unknown;
      data: string | any;
    }
  > =>
  async ({ url, method, data, params: rawParams = {} }, { getState, dispatch }: BaseQueryApi) => {
    try {
      const state = getState() as RootState;
      const token = state.auth.accessToken;

      // FIXME: This is not well-designed, it's a workaround, will revisit soon.
      const { CLIENT_ID } = CLIENT_CREDENTIAL[getEnv()];
      const { sort = [], filter = [], page_index, page_size } = toSnackCase(rawParams) as ListBaseRequestDB;
      const sortObj = sort.reduce(
        (acc, cur) => {
          Object.keys(cur).forEach((key) => {
            acc[`sort[${key}]`] = key === 'field_path' ? snakeCase(cur[key as keyof SortItemDB] as string) : (cur[key as keyof SortItemDB] as string);
          });
          return acc;
        },
        {} as Record<string, string>,
      );

      const filterObj = filter.reduce(
        (acc, cur) => {
          Object.keys(cur).forEach((key) => {
            acc[`filter[${key}]`] =
              //@ts-ignore
              key === 'field_path' ? snakeCase(cur[key as keyof StringFilterDB]) : (cur[key as keyof StringFilterDB] as string);
          });
          return acc;
        },
        {} as Record<string, string>,
      );

      const params = toSnackCase({ pageSize: page_size, pageIndex: page_index, ...sortObj, ...filterObj });
      const queryString = new URLSearchParams(params);

      const requestUrl = [`${baseUrl}${url}`, queryString.toString()].filter((i) => i).join(url.includes('?') ? '&' : '?');
      const clientId = btoa(CLIENT_ID);

      console.group();
      console.log(params);
      console.log('credentials: ', CLIENT_CREDENTIAL[getEnv()].API_HOSTNAME);
      console.log('request url: ', requestUrl);
      console.groupEnd();

      axios.defaults.headers.common['x-client-id'] = clientId;
      if (token) {
        axios.defaults.headers.common['x-access-token'] = token;
      }
      const result = await axios({ url: `${baseUrl}${url}`, method, data, params });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      const { response: { data, status } = {} } = err;
      if (status === 401) {
        delete axios.defaults.headers.common['x-access-token'];
        dispatch({ type: 'auth/logoutUser' });
      }

      return {
        error: {
          status: status,
          data: data || err.message,
        },
      };
    }
  };
