import React, { ReactElement } from 'react';
import { Account } from '../apis/types';
import Link from './Link';
import { getAccountDetailRoute, getProfileDetailRoute } from '../resources/routes-constants';
import { ACCOUNT_DETAIL_LABELS } from '../resources/text-constants';
import { NestedKeyOf } from '../types/utils';
import { useDetailFormat } from '../hooks/useDetailFormat';
import GenericSummary from './GenericSummary';
interface AccountSummaryProps {
  title: string;
  fields: NestedKeyOf<Account>[];
  data: Account;
}

const AccountSummary: React.FC<AccountSummaryProps> = (props: AccountSummaryProps) => {
  const { title, fields, data } = props;
  const fieldValueMap = useDetailFormat(data, fields).map(([fieldName, value]) => {
    let customEle;
    if (fieldName === 'friendlyId') {
      customEle = <Link to={getAccountDetailRoute(String(data.id))}>{value}</Link>;
    } else if (fieldName === 'ownerUserProfile.id') {
      customEle = <Link to={getProfileDetailRoute(String(value))}>{value}</Link>;
    } else {
      customEle = <span>{value}</span>;
    }
    return [ACCOUNT_DETAIL_LABELS[fieldName], customEle];
  }) as [string, ReactElement][];
  return <GenericSummary title={title} fieldsCollection={fieldValueMap} />;
};

export default AccountSummary;
