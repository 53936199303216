import { NestedKeyOf } from '../types/utils';
import get from 'lodash.get';
import moment from 'moment';

type DataType = number | Record<string, any> | undefined | string | boolean;

export const useDetailFormat = <T extends object>(data: T, pickedFields: NestedKeyOf<T>[]) => {
  return pickedFields.map((field: string) => {
    const fieldValue = get<T, NestedKeyOf<T>>(data, field as NestedKeyOf<T>);
    if (field.endsWith('Timestamp')) {
      return [field, moment(Number(fieldValue) * 1000).format()];
    }
    return [field, formatGeneralType(fieldValue as DataType)];
  }) as [NestedKeyOf<T>, string | number][];
};

const formatGeneralType = (value: DataType) => {
  switch (typeof value) {
    case 'undefined':
      return '-';
    case 'boolean':
      return value ? 'Y' : 'N';
    case 'object':
      return JSON.stringify(value);
    case 'string':
    case 'number':
      return value;
  }
};
