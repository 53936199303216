import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import AccountDetailPage from './pages/AccountDetailPage';
import LoginPage from './pages/LoginPage';
import AccountsPage from './pages/AccountsPage';
import { ROUTES } from './resources/routes-constants';
import Layout from './components/Layout';
import { ProtectedRoute } from './components/ProtectedRoute';
import ToothbrushesPage from './pages/ToothbrushesPage';
import ProfileDetailPage from './pages/ProfileDetailPage';
import BrushingsPage from './pages/BrushingsPage';
import ToothbrushDetailPage from './pages/ToothbrushDetailPage';
import './styles/main.sass';

const RootComponent: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path={ROUTES.HOMEPAGE_ROUTE} element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }>
          <Route path='/*' element={<NotFoundPage />} />
          <Route path={ROUTES.HOMEPAGE_ROUTE} element={<HomePage />} />
          <Route path={ROUTES.ACCOUNT_DETAIL_ROUTE} element={<AccountDetailPage />} />
          <Route path={ROUTES.PROFILE_DETAIL_ROUTE} element={<ProfileDetailPage />} />
          <Route path={ROUTES.ACCOUNTS_ROUTE} element={<AccountsPage />} />
          <Route path={ROUTES.TOOTHBRUSHES_ROUTE} element={<ToothbrushesPage />} />
          <Route path={ROUTES.TOOTHBRUSH_DETAIL_ROUTE} element={<ToothbrushDetailPage />} />
          <Route path={ROUTES.BRUSHINGS_ROUTE} element={<BrushingsPage />} />

        </Route>
        <Route path={ROUTES.LOGIN_ROUTE} element={<LoginPage />} />
      </Routes>
    </Router>
  )
}

export default RootComponent
