import React from 'react';
import { Box } from '@mui/material';
import Breadcrumbs from './Breadcrumbs';

const TopBar: React.FC = () => {
  return (
    <Box sx={{ padding: '16px 40px', borderBottom: '1px solid #E0E0E0' }}>
      <Breadcrumbs />
    </Box>
  );
};

export default TopBar;
