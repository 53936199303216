import { api } from './index';
import { Account, AccountList, ListBaseRequest } from './types';
import { ACCOUNTS_API_PATH, getAccountApiPath } from '../resources/api-constants';

const accountApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccounts: builder.query<AccountList, ListBaseRequest>({
      query: (params) => ({
        url: ACCOUNTS_API_PATH,
        method: 'get',
        params,
      }),
    }),
    getAccount: builder.query<Account, string>({
      query: (id: string) => ({ url: getAccountApiPath(id), method: 'get' }),
    }),
  }),
});

export const { useGetAccountsQuery, useGetAccountQuery } = accountApi;
