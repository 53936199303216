import React, { forwardRef } from 'react';
import { Link as MuiLink, LinkProps } from '@mui/material';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

const LinkBehavior = forwardRef<any, RouterLinkProps>(function LinkBehavior(props, ref) {
  return <RouterLink ref={ref} {...props} />;
});

const Link = ({ children, sx = {}, ...rest }: RouterLinkProps & Pick<LinkProps, 'sx' | 'underline' | 'color'>) => {
  return (
    <MuiLink sx={sx} component={LinkBehavior} {...rest}>
      {children}
    </MuiLink>
  );
};

export default Link;
