import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import RootComponent from './RootComponent';
import { persistor, store } from './states/store';
import { CLIENT_CREDENTIAL } from './resources/api-constants';
import { getEnv } from './utility/functions';

const App: React.FC = () => {
  const { GOOGLE_CLIENT_ID } = CLIENT_CREDENTIAL[getEnv()];
  console.log('client id: ', GOOGLE_CLIENT_ID, CLIENT_CREDENTIAL, getEnv());

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RootComponent />
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
  );
};

export default App;
