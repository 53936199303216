import React, { ReactElement } from 'react';
import { Profile } from '../../apis/types';
import Link from '../Link';
import { getAccountDetailRoute, getProfileDetailRoute } from '../../resources/routes-constants';
import { PROFILE_DETAIL_LABELS } from '../../resources/text-constants';
import { NestedKeyOf } from '../../types/utils';
import { useDetailFormat } from '../../hooks/useDetailFormat';
import GenericSummary from '../GenericSummary';

interface ProfileSummaryProps {
  title: string;
  fields: NestedKeyOf<Profile>[];
  data: Profile;
}

const ProfileSummary: React.FC<ProfileSummaryProps> = (props: ProfileSummaryProps) => {
  const { title, fields, data } = props;
  const fieldValueMap = useDetailFormat(data, fields).map(([fieldName, value]) => {
    let customEle;
    if (fieldName === 'id') {
      customEle = <Link to={getProfileDetailRoute(String(value))}>{value}</Link>;
    } else if (fieldName === 'userAccount.id') {
      customEle = <Link to={getAccountDetailRoute(String(value))}>{value}</Link>;
    } else {
      customEle = <span>{value}</span>;
    }
    return [PROFILE_DETAIL_LABELS[fieldName], customEle];
  }) as [string, ReactElement][];
  return <GenericSummary title={title} fieldsCollection={fieldValueMap} />;
};

export default ProfileSummary;
