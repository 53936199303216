import { Account, Brushing, Profile, Toothbrush } from '../apis/types';
import { NestedKeyOf } from '../types/utils';

export const ACCOUNT_DETAIL_LABELS = {
  friendlyId: 'Friendly ID',
  email: 'Email',
  creationTimestamp: 'Created At',
  lastUpdateTimestamp: 'Last Updated At',
  isBeta: 'Is Beta',
  deprecatedId: 'Deprecated ID',
  referralCode: 'Referral Code',
  'ownerUserProfile.deprecatedId': 'Deprecated ID',
  'ownerUserProfile.id': 'ID',
  'settings.countryCode': 'Country Code',
  'settings.firstDayOfWeek': 'First Day Of Week',
  'settings.languageCode': 'Language Code',
  'settings.timezone': 'Timezone',
  'parentalConsent.approvedAt': 'Approved At',
  'parentalConsent.email': 'Email',
  'settings.notifications.news.isActive': 'Is Active',
  'settings.notifications.weeklyDigest.isActive': 'Is Active',
} as const as Partial<Record<NestedKeyOf<Account>, string>>;

export const PROFILE_DETAIL_LABELS = {
  id: 'ID',
  deprecatedId: 'Deprecated ID',
  creationTimestamp: 'Created At',
  lastUpdateTimestamp: 'Last Updated At',
  firstName: 'First Name',
  gender: 'Gender',
  'age.birthday': 'Birthday',
  'age.bucket': 'Age Bucket',
  isUserAccountOwner: 'Is User Account Owner',
  lastName: 'Last Name',
  'metadata.createdFromApplication': 'Created From Application',
  'userAccount.id': 'ID',
  'userAccount.deprecatedId': 'Deprecated ID',
  'settings.handedness': 'Handedness',
} as const as Partial<Record<NestedKeyOf<Profile>, string>>;

export const TOOTHBRUSH_DETAIL_LABELS = {
  id: 'ID',
  deprecatedId: 'Deprecated ID',
  type: 'Type',
  model: 'Model',
  'userProfile.deprecatedId': 'Deprecated ID',
  'userProfile.id': 'ID',
  userProfile: 'User Profile',
  color: 'Color',
  macAddress: 'Mac Address',
  name: 'Name',
  serialNumber: 'Serial Number',
  'head.lastReplacedAt': 'Last Replaced At',
  firmwareVersion: 'Firmware Version',
  hardwareVersion: 'Hardware Version',
} as const as Partial<Record<NestedKeyOf<Toothbrush>, string>>;

export const BRUSHING_DETAIL_LABELS = {
  'activity.type': 'Activity Type',
  'metrics.durationInMilliseconds': 'Duration',
  createdByUserAt: 'Created At',
  creationTimestamp: 'Synchronized At',
} as const as Partial<Record<NestedKeyOf<Brushing>, string>>;
