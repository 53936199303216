import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout as BaseLayout } from '@baracoda/albatross.ui.layouts.layout';
import NavBar from './NavBar';
import TopBar from './TopBar';

const { Aside, Content } = BaseLayout;

const Layout: React.FC = () => {
  return (
    <BaseLayout>
      <Aside width={'auto'} style={{ display: 'flex' }}>
        <NavBar />
      </Aside>
      <BaseLayout>
        <TopBar />
        <Content style={{ padding: '32px', overflowY: 'auto', height: 'calc(100vh - 57px)' }}>
          <Outlet />
        </Content>
      </BaseLayout>
    </BaseLayout>
  )
};
export default Layout;
