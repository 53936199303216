import React from 'react';
import { Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ToothbrushRoute } from '../types/route-param';
import { useRequiredParams } from '../hooks/useRequiredParams';
import { useGetToothbrushQuery } from '../apis/toothbrush';
import ToothbrushSummary from '../components/ToothbrushSummary';
import Skeleton from '../components/Skeleton';

const ToothbrushDetailPage: React.FC = () => {
  const { tbId } = useRequiredParams<ToothbrushRoute>();
  const { isLoading, data: toothbrushDetail } = useGetToothbrushQuery(tbId);
  const spans = toothbrushDetail?.head ? 4 : 6;
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Toothbrush Detail
      </Typography>
      {!isLoading ? (
        <Paper sx={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Grid container spacing={2}>
            <Grid item xs={spans}>
              <ToothbrushSummary
                title="Basic"
                fields={['id', 'deprecatedId', 'type', 'name', 'model', 'color', 'firmwareVersion', 'hardwareVersion', 'macAddress', 'serialNumber']}
                data={toothbrushDetail!}
              />
            </Grid>
            {toothbrushDetail?.head ? (
              <Grid item xs={spans}>
                {<ToothbrushSummary title="Brush Head" fields={['head.lastReplacedAt']} data={toothbrushDetail!} />}
              </Grid>
            ) : null}

            <Grid item xs={spans}>
              <ToothbrushSummary title="User Profile" fields={['userProfile.id', 'userProfile.deprecatedId']} data={toothbrushDetail!} />
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Skeleton />
      )}
    </div>
  );
};

export default ToothbrushDetailPage;
