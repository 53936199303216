import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { GamificationGoal } from '../../apis/types';
import { Typography } from '@mui/material';
import ResourceList from '../ResourceList';
import React from 'react';
import { getProfileGGTApiPath } from '../../resources/api-constants';

export default function ProfileGamificationGoalTracker(props: { profileId: string }) {
  const columns: GridColDef<GamificationGoal>[] = [
    {
      field: 'gamificationGoalDefinition',
      headerName: 'Gamification Goal Definition',
      width: 400,
      valueFormatter: ({ value }) => value.id,
    },
    {
      field: 'completionInPercent',
      headerName: 'Completion In Percent',
      valueFormatter: ({ value }) => `${value * 100}%`,
    },
    {
      field: 'reward',
      headerName: 'Reward',
      width: 300,
      renderCell: ({ value }: GridRenderCellParams<GamificationGoal, GamificationGoal['reward']>) => (
        <div>
          Amount: {value!.amount}; Type: {value!.type}
        </div>
      ),
    },
  ];
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Gamification Goal Tracker
      </Typography>
      <ResourceList columns={columns} apiPath={getProfileGGTApiPath(props.profileId)} />
    </div>
  );
}
