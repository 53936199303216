import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import { HomeIcon } from '@baracoda/albatross.ui.icons.home-icon';
import Link from './Link';

const Breadcrumbs: React.FC = () => {
  return (
    <MuiBreadcrumbs>
      <Link sx={{ display: 'flex', alignItems: 'center' }} underline="hover" color="inherit" to="/">
        <HomeIcon />
        Home
      </Link>
      {/*<Typography color="text.primary">User</Typography>*/}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
