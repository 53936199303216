import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { authApi } from '../apis/auth';

const slice = createSlice({
  name: 'auth',
  initialState: { user: null, accessToken: null, refreshToken: null } as {
    user: null | any;
    accessToken: null | string;
    refreshToken: null | string;
  },
  reducers: {
    logoutUser: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.googleSignIn.matchFulfilled, (state, { payload }) => {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
    });
  },
});

export default slice.reducer;

export const { logoutUser } = slice.actions;

export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectRefreshToken = (state: RootState) => state.auth.refreshToken;
